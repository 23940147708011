export class SettingHistoryItem {
  actionObjectType: string;
  logDateTime: string;
  actionType: string;
  actionDescription: string;

  constructor(data?) {
    if (data) {
      if (data.actionObjectType) {
        this.actionObjectType = data.actionObjectType;
      }
      if (data.logDateTime) {
        this.logDateTime = data.logDateTime;
      }
      if (data.actionType) {
        this.actionType = data.actionType;
      }
      if (data.actionDescription) {
        this.actionDescription = data.actionDescription;
      }
    }
  }
}

export class SettingHistoryPagedRequest {
  limit?: number = 8;
  page?: number = 1;
  companyId?: string;

  constructor(data?: SettingHistoryPagedRequest) {
    if (data?.limit !== null && data?.limit !== undefined) {
      this.limit = data.limit;
    }
    if (data?.page !== null && data?.page !== undefined) {
      this.page = data.page;
    }
    if (data?.companyId) {
      this.companyId = data.companyId;
    }
  }
}
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuccessStatus } from '@cartwheel/web-components';
import { Store } from '@ngrx/store';
import { TaxType } from 'app/models/user-settings';
import { UpdateTaxTypeAction } from 'app/redux/actions/settings';
import { State, getGSettingsTaxTypesStatus } from 'app/redux/reducers';
import { ResponsiveService } from 'app/services/responsive.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'update-tax-type',
  templateUrl: './update-tax-type.component.html',
  styleUrls: ['./update-tax-type.component.scss']
})
export class UpdateTaxTypeComponent implements OnInit {
  public taxtTypeStatus$: Observable<SuccessStatus>;
  public isMobile$: Observable<boolean>;
  public submitTaxTypeForm: UntypedFormGroup;
  public SuccessStatus = SuccessStatus;

  constructor(
    @Inject(MatDialogRef) public dialogRef: MatDialogRef<UpdateTaxTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TaxType,
    @Inject(Store) private store: Store<State>,
    private responsiveService: ResponsiveService
  ) {
    this.submitTaxTypeForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      showTaxRate: new UntypedFormControl(false),
      taxTypeName: new UntypedFormControl('', [Validators.required]),
      taxPercentage: new UntypedFormControl(0, [Validators.min(1), Validators.max(100), Validators.required]),
      companyId: new UntypedFormControl('')
    });
  }

  public ngOnInit(): void {
    this.isMobile$ = this.responsiveService.getMobileStatus();
    this.submitTaxTypeForm.patchValue({...this.data});
    this.taxtTypeStatus$ = this.store.select(getGSettingsTaxTypesStatus);
  }

  @HostListener('keydown.esc')
  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    const newTaxType = new TaxType(this.submitTaxTypeForm.value);
    if (!this.data.id) delete newTaxType.id;
    this.store.dispatch(UpdateTaxTypeAction({payload: newTaxType}));
  }
}

import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TaxType } from 'app/models/user-settings';
import { DeleteTaxTypesAction } from 'app/redux/actions/settings';
import { State } from 'app/redux/reducers';

@Component({
  selector: 'app-delete-tax-types',
  templateUrl: './delete-tax-types.component.html',
  styleUrls: ['./delete-tax-types.component.scss']
})
export class DeleteTaxTypesComponent implements OnInit {
  msg: string;

  data: TaxType[] = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef<DeleteTaxTypesComponent>);
  private store = inject(Store<State>);

  ngOnInit(): void {
    this.msg = this.data.length > 1 ?
      ` the selected tax types ?`:
      ` "${this.data[0].taxTypeName}" ?`;
  }

  deleteTaxTypes(): void {
    this.closeDialog();
    this.store.dispatch(DeleteTaxTypesAction({payload: this.data}));
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

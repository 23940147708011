<div class="relative text-center text-white py-35 bg-secondary text-20">
  <span>
    @if (data.id) {
      Update Tax Type?
    } @else {
      Add new Tax Type?
    }
  </span>
  <cartwheel-icon-button
    color="lighterGray"
    matIconName="clear"
    type="icon-button"
    class="absolute right-10 top-10"
    (onClick)="closeDialog()"
  />
</div>
<form class="myForm" [formGroup]="submitTaxTypeForm">
  <div class="py-20 px-50">
    <cartwheel-input class="w-full" label="TAX TYPE NAME (REQUIRED)" [inputFormControl]="submitTaxTypeForm.get('taxTypeName') | formControlType"/>
    <cartwheel-input class="w-full mt-20" label="TAX PERCENTAGE (REQUIRED)" type="number" [inputFormControl]="submitTaxTypeForm.get('taxPercentage') | formControlType"/>
    <mat-checkbox class="mt-20 mb-10" color="primary" labelPosition="before" formControlName="showTaxRate">
      <span class="text-darkGray">SHOW TAX RATE</span>
    </mat-checkbox>
    @if ((taxtTypeStatus$ | async) === SuccessStatus.Error) {
      {{data.id ? 'Unable to update taxt type' : 'Unable to update taxt type'}}
    }
  </div>
  <div class="px-50 cartwheel-base-dialog-footer">
    <cartwheel-button
      statusButton
      class="w-full miniLandscape:w-145"
      type="raised-button"
      color="secondary"
      [label]="data.id ? 'Update Tax Type' : 'Add Tax Type'"
      [status]="(!submitTaxTypeForm.valid || !submitTaxTypeForm.dirty) ? SuccessStatus.Disabled : (taxtTypeStatus$ | async)"
      (onClick)="onSubmit()"
    />

    @if ((taxtTypeStatus$ | async) === null || (taxtTypeStatus$ | async) === SuccessStatus.AccountInActive) {
      <cartwheel-button
        statusButton
        class="w-full miniLandscape:w-145"
        type="text-button"
        color="middleGray"
        label="Cancel"
        [status]="SuccessStatus.Enabled"
        (onClick)="closeDialog()"
      />
    }
  </div>
</form>